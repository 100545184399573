.playerCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
  display: block;
}

.playerContainer {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(10, 10, 10) 0%,
    rgb(25, 25, 25) 75%,
    rgb(5, 5, 5) 100%
  );
}
